import React from 'react'

const OurPrograms = () => {
  return (
    <section className="bg-primary-200 py-20 text-center px-4">
      <div className="p-8 max-w-5xl container mx-auto bg-white rounded-4xl">
        <h1 className="text-2xl md:text-5xl border-b-2 mb-4 font-sans">
          <span className="font-normal">OUR</span>{' '}
          <span className="font-bold">PROGRAMS</span>
        </h1>
        <div className="flex flex-col space-y-4">
          <ul className="grid grid-flow-row md:grid-flow-col justify-items-center font-bold">
            <li
              className="py-2 w-2/3 md:w-full border-b-2 md:border-b-0 md:border-r-2 flex items-center justify-center"
              style={{ color: '#007DBC' }}
            >
              LEVELS OF
              <br />
              PROFICIENCY
            </li>
            <li
              className="py-2 w-2/3 md:w-full border-b-2 md:border-b-0 md:border-r-2 flex items-center justify-center"
              style={{ color: '#A3CF60' }}
            >
              3 ELEMENTS
            </li>
            <li
              className="py-2 flex items-center justify-center"
              style={{ color: '#FFCF2E' }}
            >
              CUSTOMIZED
              <br />
              CURRICULUM
            </li>
          </ul>
          <p className="py-6">
            Through the use of motivating developmentally appropriate activities
            students will master the English language while gaining the
            fundamental skills necessary to achieve academic excellence.
          </p>

          {/* <ContainedButton>LEARN MORE</ContainedButton> */}
        </div>
      </div>
    </section>
  )
}

export default OurPrograms
