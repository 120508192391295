import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Little from 'components/svgs/little'
import Gears from 'components/pages/home/graphics/Gears'
import KidSlide from 'static/images/home/kids-slide.jpg'
import Bullet from 'static/svgs/bullet-arrow.svg'

const WhoIsLittleScholar = () => {
  const breakpoints = useBreakpoint()

  // TODO: need auto slider at the end

  return (
    <section
      id="who-is"
      className="container mx-auto divide-y-2 px-4 py-4 md:py-6 text-center md:text-left"
    >
      <div className="w-full pb-6 md:py-16 flex flex-col md:flex-row md:items-start md:space-x-12">
        <div className="md:flex-1">
          <img
            className="mx-auto w-full max-w-xs md:max-w-sm md:w-5/6 my-6 md:my-0"
            style={{ order: breakpoints.sm ? null : 4 }}
            src={KidSlide}
            alt="Kids on a slide"
          />
        </div>
        <div className="md:w-2/3 py-4 md:py-6 md:pb-10 flex flex-col space-y-10 items-start">
          <h1 className="w-full flex flex-col flex-wrap lg:flex-row text-2xl lg:text-5xl overflow:hidden">
            <nobr className="pr-4">WHO IS </nobr>
            <nobr className="flex flex-row justify-center md:justify-start">
              <Little className="h-8 lg:h-14 pr-4" />

              <span>{' SCHOLAR?'}</span>
            </nobr>
          </h1>
          <p className="text-primary-800 md:max-w-lg">
            <span className="font-bold">Little Scholar (LS)</span> is an
            organization dedicated to delivering a student focused and highly
            contextualized American English learning program that accommodates
            all levels of proficiency.
          </p>

          <div className="flex flex-row space-x-8">
            {/* {isMdScreen && (
              <img
                className="mx-auto md:mx-0 max-w-xs md:max-w-none md:w-2/5 my-6 md:my-0"
                src={KidSlide}
              />
            )} */}

            <div className="flex flex-col">
              <h2>OUR MISSION</h2>
              <p className="md:max-w-lg lg:max-w-xl ">
                We aim to create a developmentally appropriate learning
                experience for a child new to English Language learning.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-4 md:py-16 flex flex-col lg:flex-row-reverse space-y-6 lg:space-y-0 justify-end">
        <Gears
          className="w-full px-6 my-8 md:w-3/5 lg:w-2/5 mx-auto"
          style={{ order: breakpoints.sm ? null : 4 }}
        />

        <div className="lg:mr-8">
          <h2 className="mb-10">ALIGNED WITH STATE STANDARDS</h2>
          <ul
            className="pl-10 lg:max-w-md text-primary-800 flex flex-col space-y-4 text-left"
            style={{ listStyleImage: `url(${Bullet})` }}
          >
            <li>Research based and Standards Aligned Curriculum</li>
            <li>
              Professional Training
              <ul
                className="pl-10 mt-4 flex flex-col space-y-4"
                style={{ listStyleImage: `url(${Bullet})` }}
              >
                <li>Assessments</li>
                <li>Teacher Instruction</li>
                <li>Effective learning environment</li>
                <li>Student Engagement</li>
              </ul>
            </li>
            <li>
              Interactive Curriculum with finger plays, songs, movement,
              activities, stories and more
            </li>
          </ul>
        </div>
      </div>

      {/* <div className="py-16 flex flex-row">
        Auto slider
      </div> */}
    </section>
  )
}

export default WhoIsLittleScholar
