import React from 'react'
import { css } from 'twin.macro'

import KidsBanner from 'static/images/home/kids-banner.jpg'
import Logo from 'components/logo'
import Mile from 'components/pages/home/graphics/MileHeader'
import Millie from 'components/pages/home/graphics/MillieHeader'

const Banner = () => {
  return (
    <section className="relative md:mb-24 lg:mb-32 flex flex-col">
      <p className="text-2xl md:text-5xl font-serif text-center text-primary-800 font-bold py-2 border-b-14 border-primary-200">
        ACADEMIC EXCELLENCE FOR <nobr>DEVELOPING MINDS</nobr>
      </p>

      <img
        className="border-b-12 border-primary-800"
        src={KidsBanner}
        alt="banner"
      />

      <div className="md:absolute md:left-0 md:bottom-minus8rem pb-8 w-full flex flex-col items-center justify-center border-b-12 border-primary-800">
        <div className="container flex flex-row justify-center items-end h-40 md:h-56">
          <Mile
            css={[
              css`
                height: 100%;
                max-height: 100%;
              `,
            ]}
          />
          <Logo className="w-2/5 sm:w-1/5 max-h-full" />
          <Millie
            css={[
              css`
                height: 100%;
              `,
            ]}
          />
        </div>
        <p className="mt-4 md:mt-0 font-serif font-semibold text-center text-xl md:text-3xl text-primary-800">
          Embrace the future with <nobr>Little Scholar</nobr>
        </p>
      </div>
    </section>
  )
}

export default Banner
