import React from 'react'

import SEO from 'components/seo'
import Banner from 'components/pages/home/banner'
import WhoIsLittleScholar from 'components/pages/home/who-is'
import OurPrograms from 'components/pages/home/our-programs'
import Layout from 'components/layouts/layout'

/**
 * Home Page
 */
export default ({ location }) => (
  <Layout fullWidth={true}>
    <SEO title="Home" path={location.pathname} />
    <Banner />
    <WhoIsLittleScholar />
    {/* TODO: Moving icons */}
    <OurPrograms />
  </Layout>
)
